<template>
  <ion-content :fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-title>Reset Password</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-card>
      <ion-card-content>
        <ion-item>
          <ion-label position="floating">Password Baru</ion-label>
          <ion-input v-model="passwordBaru" type="password"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Konfirmasi Password Baru</ion-label>
          <ion-input
            v-model="passwordBaruKonfirmasi"
            type="password"
          ></ion-input>
        </ion-item>
        <ion-button
          class="mt-4"
          @click="updatingPassword"
          :disabled="loading || !passwordBaru || !passwordBaruKonfirmasi"
          expand="full"
        >
          {{ !loading ? "RESET PASSWORD" : "Memperbarui Password" }}
        </ion-button>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  modalController,
  toastController,
} from "@ionic/vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ResetPassword",
  props: {
    data: { type: Object, default: null },
  },
  data() {
    return {
      passwordLama: null,
      passwordBaru: null,
      passwordBaruKonfirmasi: null,
      loading: false,
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCard,
    IonInput,
    IonCardContent,
    IonItem,
    IonButton,
    IonLabel,
  },
  mounted() {
    console.log(this.$route.params.hash);
  },
  methods: {
    async updatingPassword() {
      if (this.passwordBaru != this.passwordBaruKonfirmasi) {
        const toast = await toastController.create({
          message: "Password baru anda tidak sama dengan konfirmasi password",
          duration: 2500,
        });
        return toast.present();
      }
      this.loading = true;
      const formdata = new FormData();
      formdata.append("hash", this.$route.params.hash);
      formdata.append("new_pwd", this.passwordBaru);
      formdata.append("new_confirm_pwd", this.passwordBaruKonfirmasi);
      await axios
        .post("update-password", formdata)
        .then(async ({ data }) => {
          this.loading = false;
          const toast = await toastController.create({
            message: data.message,
            duration: 2500,
          });
          toast.present();
          return this.$router.replace("/");
        })
        .catch(async ({ response }) => {
          this.loading = false;
          this.passwordBaru = null;
          this.passwordBaruKonfirmasi = null;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2500,
          });
          return toast.present();
        });
    },
  },
});
</script>